import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import MainLayout from "./components/Layout/MainLayout";
import Login from "./components/Login/Login.js";
import Dashboard from "./components/Dashboard/Dashboard.js";
import Test from "./components/Layout/Test";
import ConversationHistory from '../src/components/ConversationHistory/ConversationHistory';
import Personalization from "./components/Personalization/personalization.js";
import Share from "./components/Share/ShareComponent.js";

function App() {
  useEffect(() => {
    const inIframe = () => {
      try {
        return window.self !== window.top;
        
      } catch (e) {
        return true;
      }
    };
    



    if (inIframe()) {
   
      
        
     

      const typedText = document.querySelector('#typedText');
      const rightColumn = document.querySelector('.right-column');
     

      const start_central_column = document.querySelector('.center-column');
      // const new_background_video_container = document.querySelector('.new-background-video-container');
      // const start_video = document.querySelector('#yourVideoDivId video');
      // const central_navbar_backside_color = document.querySelector('.central-navbar-backside-color');
      // const central_column_bbtn_wrapper = document.querySelector('.central-column-bbtn-wrapper');
      // const chat_help_btn_img_st2_list = document.querySelectorAll('.chat-help-btn-img-st2');
      // const stage_2_span = document.querySelectorAll('.stage-2 span');
      // const stage_3_span = document.querySelectorAll('.stage-3 span');
      // const chat_help_btns_container_stage_2_span = document.querySelectorAll('.chat-help-btns-container .stage-2 button');
      // const chat_help_btns_container_stage_3_span = document.querySelectorAll('.chat-help-btns-container .stage-3 button');
      // const chat_help_btns_container_stage = document.querySelectorAll('.chat-help-btns-stage');


      if (rightColumn && start_central_column ) {
        rightColumn.style.display = 'none';
        // start_central_column.style.paddingTop = '20px';
        typedText.style.minHeight='44px';
        // new_background_video_container.style.height = '240px';
        // start_video.style.marginTop = '0px';
        // central_navbar_backside_color.style.display = 'none';
        // central_column_bbtn_wrapper.style.top = '0';
        // chat_help_btn_img_st2_list.forEach(element => {
        //   element.style.minWidth = '32px';
        //   element.style.maxWidth = '32px';
        // });
        // stage_2_span.forEach(element => {
        //   element.style.fontSize = '12px';
        // });
        // stage_3_span.forEach(element => {
        //   element.style.fontSize = '12px';
        // });
        // chat_help_btns_container_stage_2_span.forEach(element => {
        //   element.style.gap = '2px';
        // });
        // chat_help_btns_container_stage_3_span.forEach(element => {
        //   element.style.gap = '2px';
        // });
        // chat_help_btns_container_stage.forEach(element => {
        //   element.style.paddingTop = '8px';
        // });
      }
    }



    // const iframeId = inIframe();
    // if (iframeId) {
    //   console.log("Inside iframe with ID:", iframeId);
    // } else {
    //   console.log("Not inside an iframe.");
    // }

    console.log(inIframe() ? "This website is wrapped in an iframe." : "This website is not wrapped in an iframe.");
  }, []);
  return (
    <Routes>
      <Route path="/" element={<MainLayout />} />
      {/* <Route path="/" element={<Test />} /> */}

      <Route path="/login" element={<Login />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/Onangwatgo-Dálach" element={<ConversationHistory />} />
      <Route path="/personalization" element={<Personalization />} />
      <Route path="/share/:session" element={<Share />} />

    </Routes>
  );
}

export default App;
