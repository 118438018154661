import React, { useEffect, useRef, useState } from "react";

import stop from "../../img/chat/stop_button.svg";
import loadingModel from "../../img/knightingale_typing.png";
import play from "../../img/chat/listen_button.svg";
import copy from "../../img/chat/copy.svg";
import share_line from "../../img/chat/share_line.svg";
import heart from "../../img/chat/heart.svg";
import pose_1 from "../../img/chat/pose_1.jpg";
import pose_2 from "../../img/chat/pose_2.jpg";
import pose_3 from "../../img/chat/pose_3.jpg";
import pose_4 from "../../img/chat/pose_4.jpg";
import pose_5 from "../../img/chat/pose_5.jpg";
import pose_6 from "../../img/chat/pose_6.jpg";
import pose_7 from "../../img/chat/pose_7.jpg";
import dot_menu from "../../img/chat/dot_menu.png";
import up_arrow from "../../img/chat/up_arrow.svg";
import knightingale_background_video from "../../videos/chat/idle.mp4";
import knightingale_background_video_mobile from "../../videos/chat/idle.mp4";
import knightingale_character_loop from "../../videos/chat/idle.mp4";
import careReminderMp4t from "../../videos/chat/lip_synced.mp4";
import knightingale_character_loop_poster from "../../img/chat/knightingale_character_loop_poster.jpg";



import PlayLoading from "../../img/chat/playloading.js";
import user_profile from "../../img/chat/user_profile.png";
import knightingale_profile_image from "../../img/chat/knightingale_profile.png";
import { useShareWindow } from "../../context/ShareWindowContext.js";

import "../Chat/Chat.css";

const Chat = ({
  userHistory,
  currentlyPlayingIndex,
  isVoiceSynthesisInProgress,
  handleStopButtonClick,
  playAudio,
  showLoadingIndicator,
  handlechtst1ButtonClick,
  setInput,
  responseTexts,
  imgSuggession,
  suggessionTitle,
  selectedOption,
  boxSuggession,
}) => {
  const outputContainerRef = useRef(null);
  const outputinnerContainerRef = useRef(null);
  const [typingLoaded, setTypingLLoaded] = useState(false);
  const [showOriginal, setShowOriginal] = useState(true);
  const { setIsButtonDisabled, textCount, setTextCount, minMaxHeight, setShowShareWindow, audioVideoChange, setAudioVideoChange } = useShareWindow();
  const [imageUrl, setImageUrl] = useState(null);
  const images = [heart];
  const [scrollHeightCal, setScrollHeight] = useState(0);
  const [showTooltip, setShowTooltip] = useState(false);
  const [hoverTimeout, setHoverTimeout] = useState(null);
  const [showButton, setShowButton] = useState(false);
  const timeoutRef = useRef(null);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);
  const [openMenuIndex, setOpenMenuIndex] = useState(null);
  const buttonRefs = useRef([]);
  const menuRef = useRef(null);

  console.log(minMaxHeight)



  const containerHeightStyle = {
    minHeight: minMaxHeight === 0 ? `${window.innerHeight - minMaxHeight - 45}px` : `${window.innerHeight - minMaxHeight - 60}px`,
    maxHeight: minMaxHeight === 0 ? `${window.innerHeight - minMaxHeight - 45}px` : `${window.innerHeight - minMaxHeight - 60}px`
  };


  useEffect(() => {
    const randomIndex = Math.floor(Math.random() * images.length);
    setImageUrl(images[randomIndex]);
  }, []);


  // useEffect(() => {
  //   if (selectedOption === "option1") {
  //     setColorStyl("palette-1");
  //   } else if (selectedOption === "option2") {
  //     setColorStyl("palette-2");
  //   } else if (selectedOption === "option3") {
  //     setColorStyl("palette-3");
  //   } else if (selectedOption === "option4") {
  //     setColorStyl("palette-4");
  //   } else if (selectedOption === "option5") {
  //     setColorStyl("palette-5");
  //   } else if (selectedOption === "option6") {
  //     setColorStyl("palette-6");
  //   } else if (selectedOption === "option7") {
  //     setColorStyl("palette-7");
  //   } else if (selectedOption === "option8") {
  //     setColorStyl("palette-8");
  //   }
  // }, [selectedOption]);

  const handleTextChange = () => {
    const answerElement = document.getElementById("answer");

    if (answerElement) {
      const text = answerElement.innerText;
      const count = text.length;
      setTextCount(count);
      setIsButtonDisabled(count === 0);
    }
  };

  useEffect(() => {
    handleTextChange();

    document.addEventListener("DOMSubtreeModified", handleTextChange);

    return () => {
      document.removeEventListener("DOMSubtreeModified", handleTextChange);
    };
  }, []);

  // Use state to store an array of refs
  const [textRefs, setTextRefs] = useState([]);

  // This effect will initialize the refs when the component mounts or userHistory changes
  useEffect(() => {
    setTextRefs((prevTextRefs) =>
      userHistory.map((_, index) => prevTextRefs[index] || React.createRef())
    );
  }, [userHistory]);


  useEffect(() => {
    console.log(textRefs);
  }, [textRefs]);


  const handleCopyClick = (textRef, setShowOriginal) => () => {
    if (textRef && textRef.current) {
      const textToCopy = textRef.current.innerText;
      const textarea = document.createElement("textarea");
      textarea.value = textToCopy;
      document.body.appendChild(textarea);


      textarea.select();
      document.execCommand("copy");
      document.body.removeChild(textarea);
      setTimeout(() => {
      }, 2000);
    }
    clearTimeout(hoverTimeout);
    setShowTooltip(false);
  };

  useEffect(() => {
    scrollToBottom();
    setTypingLLoaded(true);
  }, [userHistory, showLoadingIndicator]);

  const scrollToBottom = () => {
    const parentElement = document.querySelector(".q-and-a-container");
    const outputContainer = outputContainerRef.current;

    if (outputContainer) {

      const lastChild = outputContainer.lastChild;
      const childElements = parentElement.querySelectorAll(".main-q-a-box");

      if (lastChild && childElements.length > 0) {
        const containerHeight = outputContainer.clientHeight;
        const lastChildHeight = lastChild.clientHeight;
        const scrollHeight = outputContainer.scrollHeight;
        const lastChilds = childElements[childElements.length - 1];
        const lastChildHei = lastChilds.getBoundingClientRect().height;

        if (lastChildHei > containerHeight - 45) {
          outputContainer.scrollTop =
            scrollHeight -
            (lastChildHei) -
            (lastChildHeight - (containerHeight - 30));
          console.log(lastChildHei)
        } else {
          if (scrollHeight - containerHeight <= lastChildHeight) {
            outputContainer.scrollTop = scrollHeight - (containerHeight - 50);
          } else {
            lastChild.scrollIntoView({ behavior: "smooth", block: "end" });
          }
        }
      }
    }
  };

  const scrollTopToBottom = () => {
    const outputContainer = outputContainerRef.current;
    const scrollHeight = (outputContainer.scrollHeight);
    const duration = 2500 * scrollHeight / 2000;
    const startTime = performance.now();
    const startScrollTop = outputContainer.scrollTop;

    const animateScroll = (currentTime) => {
      const elapsedTime = currentTime - startTime;
      const progress = Math.min(elapsedTime / duration, 1);
      outputContainer.scrollTop = startScrollTop + (scrollHeight - startScrollTop) * progress;

      if (elapsedTime < duration) {
        requestAnimationFrame(animateScroll);
      }
    };

    requestAnimationFrame(animateScroll);
    setShowButton(false);
  };



  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
      setScreenHeight(window.innerHeight);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getHeight = () => {
    if (screenWidth <= 767) {
      return `${screenHeight * 0.65}px`;
    } else {
      return `${screenHeight * 0.55}px`;
    }
  };

  const suggessionBorderColor = {
    borderColor: boxSuggession,
  };





  useEffect(() => {
    function handleClickOutside(event) {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target) &&
        buttonRefs.current.every(buttonRef => !buttonRef.contains(event.target))
      ) {
        setOpenMenuIndex(null);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [buttonRefs]);

  const handleButtonClick = index => {
    setOpenMenuIndex(prevIndex => (prevIndex === index ? null : index));
  };

  const addDotMenuRef = index => element => {
    buttonRefs.current[index] = element;
  };


  // useEffect(() => {
  //   function handleClickOutside(event) {
  //     if (
  //       menuRef.current &&
  //       !menuRef.current.contains(event.target) &&
  //       buttonRefs.current.every(buttonRef => !buttonRef.contains(event.target))
  //     ) {
  //       setOpenMenuIndex(null);
  //     }
  //   }

  //   document.addEventListener('mousedown', handleClickOutside);
  //   return () => {
  //     document.removeEventListener('mousedown', handleClickOutside);
  //   };
  // }, [buttonRefs]);

  // const handleButtonClick = index => {
  //   setOpenMenuIndex(prevIndex => (prevIndex === index ? null : index));
  // };

  // const addDotMenuRef = index => element => {
  //   buttonRefs.current[index] = element;
  // };




  useEffect(() => {
    const container = outputContainerRef.current;
    container.addEventListener('scroll', handleScroll);
    return () => {
      container.removeEventListener('scroll', handleScroll);
      clearTimeout(timeoutRef.current);
    };
  }, []);


  const handleScroll = () => {
    const scrollTop = outputContainerRef.current.scrollTop;
    const newScrollHeight = scrollTop * -1;
    setScrollHeight(newScrollHeight);
    setShowButton(newScrollHeight > 300);
  };


  return (
    <div className="q-and-a-container-wrapper start-q-and-a-container" style={{ position: "relative" }}
    >
      {showButton && <div className="top-to-bottom" onClick={scrollTopToBottom} >
        <img src={up_arrow} alt="dot_menu" />
      </div>
      }
      <div
        style={containerHeightStyle}
        className="output-area  start-output-area scrollbar "
        id="scrollbar2"
        ref={outputContainerRef}
        onScroll={handleScroll}
      >
        <pre className="q-and-a-container" ref={outputinnerContainerRef} id="klkl">
          {selectedOption === "option1" && (
            <div className="main-q-a-box">
              <div
                className="chat-suggesst-wrapper"
                style={{ height: getHeight() }}
              >
                <div className="chat-suggesst-title  ">
                  <div className="chat-suggesst-title-inner  ">
                    <div style={{ height: "5px" }}></div>
                    {imageUrl && <img
                      className="chat-suggesst-img"
                      src={imageUrl}
                      alt=""
                    />}

                    {/* 
                      <video
                    className="video-bg"
                    playsInline
                    autoPlay
                    loop
                    muted
                    src={knightingale_background_video}
                  /> */}
                    <div className="desk_home_mobile_video_wrapper">
                      <div className="desk_home_video_container">
                        <div className="new-background-video-container-chat videoclass">
                          <div
                            style={{
                              position: `${audioVideoChange ? 'static' : 'absolute'}`,
                              width: '100%'

                            }}
                          >

                            <video
                              className={`new-background-video-container-chat-video `}
                              style={{
                                width:'300px'
                                // opacity: `${audioVideoChange ? '0' : '1'}`, left: '-55%',
                                // zIndex: '-10',
                                // position: `${audioVideoChange ? 'relative' : 'static'} `
                              }}
                              playsInline
                              autoPlay
                              loop
                              muted
                              poster={knightingale_character_loop_poster}
                              src={knightingale_character_loop}
                            />
                          </div>
                          <div
                            style={{
                              position: `${audioVideoChange ? 'absolute' : 'static'}`,
                              opacity: `${audioVideoChange ? '1' : '0'}`,
                              transition: 'opacity 200ms ease',
                              width: '100%'
                            }}
                          >

                            <video
                              className="new-background-video-container-chat-video"
                              style={{
                                width:'300px'
                                // zIndex: '-10',
                                // position: `${audioVideoChange ? 'relative' : 'static'} `, opacity: `${audioVideoChange ? '1' : '0'} `
                              }
                              }
                              laysInline
                              autoPlay
                              loop
                              muted
                              poster={knightingale_character_loop_poster}
                              src={careReminderMp4t}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="chat-suggesst-title-text">

                      {suggessionTitle}
                    </div>
                  </div>
                </div>
                <div className="chat-starters start-suggession-starter">
                  <div className="suggetion-wrapper">
                    <div className="suggetion-container">
                      {" "}
                      <button
                        onClick={() => {
                          handlechtst1ButtonClick();
                          setInput(responseTexts[0]);
                        }}
                        style={suggessionBorderColor}
                      >
                        <div className="suggetion-icon">
                          <svg
                            version="1.0"
                            xmlns="http://www.w3.org/2000/svg"
                            width="20.000000px"
                            height="20.000000px"
                            viewBox="0 0 32.000000 32.000000"
                            preserveAspectRatio="xMidYMid meet"
                          >
                            <g
                              transform="translate(0.000000,32.000000) scale(0.100000,-0.100000)"
                              stroke="none"
                            >
                              <path
                                className="colored-path"
                                fill={boxSuggession}
                                d="M10 246 l0 -54 50 -12 c28 -7 52 -15 55 -19 2 -5 -20 -13 -50 -20 l-55 -12 0 -55 c0 -41 4 -54 15 -54 24 0 285 128 285 140 0 11 -262 140 -286 140 -10 0 -14 -15 -14 -54z"
                              />
                            </g>
                          </svg>
                        </div>
                        <div className="response-wrapper">
                          {responseTexts[0]}
                        </div>
                      </button>
                    </div>
                  </div>
                  <div className="suggetion-wrapper">
                    <div className="suggetion-container">
                      {" "}
                      <button
                        onClick={() => {
                          handlechtst1ButtonClick();
                          setInput(responseTexts[1]);
                        }}
                        style={suggessionBorderColor}
                      >
                        <div className="suggetion-icon">
                          <svg
                            version="1.0"
                            xmlns="http://www.w3.org/2000/svg"
                            width="20.000000px"
                            height="20.000000px"
                            viewBox="0 0 32.000000 32.000000"
                            preserveAspectRatio="xMidYMid meet"
                          >
                            <g
                              transform="translate(0.000000,32.000000) scale(0.100000,-0.100000)"
                              stroke="none"
                            >
                              <path
                                className="colored-path"
                                fill={boxSuggession}
                                d="M10 246 l0 -54 50 -12 c28 -7 52 -15 55 -19 2 -5 -20 -13 -50 -20 l-55 -12 0 -55 c0 -41 4 -54 15 -54 24 0 285 128 285 140 0 11 -262 140 -286 140 -10 0 -14 -15 -14 -54z"
                              />
                            </g>
                          </svg>
                        </div>
                        <div className="response-wrapper">
                          {responseTexts[1]}
                        </div>
                      </button>
                    </div>
                  </div>
                  <div className="suggetion-wrapper">
                    <div className="suggetion-container">
                      {" "}
                      <button
                        onClick={() => {
                          handlechtst1ButtonClick();
                          setInput(responseTexts[2]);
                        }}
                        style={suggessionBorderColor}
                      >
                        <div className="suggetion-icon">
                          <svg
                            version="1.0"
                            xmlns="http://www.w3.org/2000/svg"
                            width="20.000000px"
                            height="20.000000px"
                            viewBox="0 0 32.000000 32.000000"
                            preserveAspectRatio="xMidYMid meet"
                          >
                            <g
                              transform="translate(0.000000,32.000000) scale(0.100000,-0.100000)"
                              stroke="none"
                            >
                              <path
                                className="colored-path"
                                fill={boxSuggession}
                                d="M10 246 l0 -54 50 -12 c28 -7 52 -15 55 -19 2 -5 -20 -13 -50 -20 l-55 -12 0 -55 c0 -41 4 -54 15 -54 24 0 285 128 285 140 0 11 -262 140 -286 140 -10 0 -14 -15 -14 -54z"
                              />
                            </g>
                          </svg>
                        </div>
                        <div className="response-wrapper">
                          {responseTexts[2]}
                        </div>
                      </button>
                    </div>
                  </div>
                  <div className="suggetion-wrapper">
                    <div className="suggetion-container">
                      {" "}
                      <button
                        onClick={() => {
                          handlechtst1ButtonClick();
                          setInput(responseTexts[3]);
                        }}
                        style={suggessionBorderColor}
                      >
                        <div className="suggetion-icon">
                          <svg
                            version="1.0"
                            xmlns="http://www.w3.org/2000/svg"
                            width="20.000000px"
                            height="20.000000px"
                            viewBox="0 0 32.000000 32.000000"
                            preserveAspectRatio="xMidYMid meet"
                          >
                            <g
                              transform="translate(0.000000,32.000000) scale(0.100000,-0.100000)"
                              stroke="none"
                            >
                              <path
                                className="colored-path"
                                fill={boxSuggession}
                                d="M10 246 l0 -54 50 -12 c28 -7 52 -15 55 -19 2 -5 -20 -13 -50 -20 l-55 -12 0 -55 c0 -41 4 -54 15 -54 24 0 285 128 285 140 0 11 -262 140 -286 140 -10 0 -14 -15 -14 -54z"
                              />
                            </g>
                          </svg>
                        </div>
                        <div className="response-wrapper">
                          {responseTexts[3]}
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {userHistory.map((entry, index) => (
            <div className="main-q-a-box" key={index}>
              <div className="client-msg-bubble">
                <div className="profile-wrapper-chat-user">
                  <div className="profile-inner-chat-user">
                    <div className="profile-container-chat-user">
                      <div className="profile-container-img-user">
                        <img src={user_profile} alt="user_profile" />
                      </div>
                      <div className="profile-container-name">
                        You
                      </div>
                    </div>
                  </div>
                </div>
                <h2 className="client-questions">{entry.input}</h2>
              </div>

              <div className="bot-msg-bubble">
                <div className="profile-wrapper-chat">
                  <div className="profile-inner-chat">
                    <div className="profile-container-chat">
                      <div className="profile-container-img">
                        <img src={knightingale_profile_image} alt="knightingale profile image" />
                      </div>
                      <div className="profile-container-name">
                        Knightingale
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bot-msg-bubble-container">
                  <div className="play-button-container">
                    {isVoiceSynthesisInProgress && (
                      <div className="play_loading_wrapper">
                        <div className="play_loading_container">
                          <PlayLoading />
                        </div>
                      </div>
                    )}
                    {!isVoiceSynthesisInProgress &&
                      (currentlyPlayingIndex === index ? (
                        <button
                          type="button"
                          onClick={() => { handleStopButtonClick(); setAudioVideoChange(false); }}
                          className={`stop-button ${entry.response ? "" : "hidden"
                            }`}
                        >
                          <img
                            src={stop}
                            height={20}
                            width={20}
                            alt="Knightingale said"
                            className="stop-icon"
                          />
                        </button>
                      ) : (
                        <button
                          type="button"
                          onClick={() => playAudio(entry.response, index)}
                          className={`play-audio-button ${entry.response ? "" : "hidden"
                            }`}
                        >
                          {currentlyPlayingIndex !== index ? (
                            <img
                              src={play}
                              height={30}
                              width={30}
                              alt="Knightingale says"
                              className="play-icon"
                            />
                          ) : null}
                        </button>
                      ))}

                    <div key={index} className="play-button-menu" ref={addDotMenuRef(index)} onClick={() => { handleButtonClick(index) }} >
                      <img src={dot_menu} alt="dot_menu" />
                      {openMenuIndex === index && (<div>
                        <div ref={menuRef} className="dotmenu-wrapper">
                          <div className="dotmenu-container">
                            <div className="dotmenu-menu" onClick={() => {
                              handleCopyClick(textRefs[index], setShowOriginal)();
                            }}>
                              <img src={copy} alt="dot_menu" />
                              Copy Text
                            </div>
                            <div className="dotmenu-menu" onClick={() => setShowShareWindow(true)}>
                              <img src={share_line} alt="dot_menu" />
                              Share Thread
                            </div>
                          </div>
                        </div>
                      </div>)
                      }
                    </div>

                  </div>
                  <div className="bot-msg-text" ref={textRefs[index]}>
                    <p
                      dangerouslySetInnerHTML={{ __html: entry.response }}
                      className="answer-for-questions"
                      id="answer"
                      onChange={handleTextChange}
                    ></p>
                  </div>
                </div>
              </div>
            </div>
          ))}
          {showLoadingIndicator && (
            <div className="container">
              <div className="card">
                <div className="card-body">
                  <div className="chat-thread">
                    <div className="message">
                      <div className="message-content">
                        <div className="loading-model">
                          <img src={loadingModel} alt="Knightingale Typing" />
                        </div>
                        <div className="typing-indicator">
                          <span></span>
                          <span></span>
                          <span></span>
                        </div>
                        <p>Knightingale is typing</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </pre>
      </div>
    </div>
  );
};

export default Chat;
