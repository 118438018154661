import React, { useEffect, useRef, useState } from "react";

import navItem1 from "../../img/profile_iconm.png";
import user from "../../img/profile/knightingale_profile_image.png";

import mainlogo from "../../img/logo/knightingale_logo.png";
import LoginPage from "../Login/Login";
import "../Navbar/Navbar.css";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import userProfile from "../../img/profile/profile.svg";
import userPersonalization from "../../img/profile/personalization.svg";

const Navbar = ({setCenterHome}) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  const submenuRef = useRef(null);

  const openPopup = () => {
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  const navigate = useNavigate();
  const handlePersonalization = () => {
    navigate("/personalization");
  };

  useEffect(() => {
    const handlePageClick = () => {

    };

    document.addEventListener("click", handlePageClick);
    return () => {
      document.removeEventListener("click", handlePageClick);
    };
  }, []);


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (submenuRef.current && !submenuRef.current.contains(event.target)) {
        setIsSubMenuOpen(false);

      }
    };

    const handleDocumentClick = (event) => {
      handleClickOutside(event);
    };

    // Attach the event listener when the component mounts
    document.addEventListener('click', handleDocumentClick);

    // Detach the event listener when the component unmounts
    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, []); // Empty dependency array means this effect runs only once, similar to componentDidMount

  const handleSubMenuToggle = (event) => {
    // Prevent the click inside the submenu from triggering the outside click handler

    event.stopPropagation();
    // Toggle the submenu
    setIsSubMenuOpen(!isSubMenuOpen);
  };

  return (
    <>
      <nav className="navbar">
        <ul className="navbar-items">
          <li className="logo-wrapper start-menu-logo-wrapper menu-logo-kps-wrapper ">
            <a href="https://knightingale.ai/">
              <img
                className="logo-kps menu-logo-kps "
                src={mainlogo}
                alt="Image 1"
              />
            </a>
          </li>
          {/* <li onClick={handlePersonalization} className="start-logo-profile user-login-img">
              <a href="#">
                <img className="start-logo-profile-img" src={navItem1} alt="Image 1" />
              </a>
            </li> */}
          <li className="start-logo-profile user-login-img">
            <div className=" profile-link">
            <button className="profile-button">

                <img
                  // onClick={()=>(setIsSubMenuOpen(!isSubMenuOpen))}
                  onClick={handleSubMenuToggle}
                  className="start-logo-profile-img"
                  src={navItem1}
                  alt="Image 1"
                />
              </button>

              {isSubMenuOpen && (
                <div ref={submenuRef}  className="profile-wrapper">
                  <div className="profile-container">
                    <div className="profile-innner-container">
                      <div className="profile-link-wrapper">
                        <img
                          src={userProfile}
                          className="user-img profile-img"
                          alt="user"
                        />
                        <a href="#" onClick={openPopup}>
                          Login
                        </a>
                      </div>
                      {/* <hr></hr>
                      <div className="profile-link-wrapper">
                        <img
                          src={userPersonalization}
                          className="personalization-img  profile-img"
                          alt="personalization"
                        />
                        <Link to={"/personalization"}>Personalization</Link>
                      </div> */}
                    </div>
                  </div>
                  <div className="up-arrow"></div>
                </div>
              )}
            </div>
          </li>
          {/* <li onClick={openPopup} className="start-logo-profile user-login-img">
            <a href="#">
              <img
                className="start-logo-profile-img"
                src={navItem1}
                alt="Image 1"
              />
            </a>
          </li> */}
        </ul>
        <ul className="mobile-navbar-items start-mobile-navbar-items">
          <li className="user-li start-nav-li start-nav-img">
            <a href="#">
              <img className="user-kps " src={user} alt="Image 1" />
            </a>
          </li>
          <li className="logo-li ">
            <a href="https://knightingale.ai/">
              <img className="logo-kps   menu-icon" src={mainlogo} alt="Image 1" />
            </a>
          </li>
          
          {
            <li className="menu-li start-menu-li">
            {/* <div className=" profile-link"> */}

              <a href="#">
                <img className="menu-icon" src={navItem1} alt="Login"    onClick={handleSubMenuToggle}/>
              </a>
              {isSubMenuOpen && (
                <div ref={submenuRef}  className="profile-wrapper">
                  <div className="profile-container">
                    <div className="profile-innner-container">
                      <div className="profile-link-wrapper">
                        <img
                          src={userProfile}
                          className="user-img profile-img"
                          alt="user"
                        />
                        <a href="#" onClick={openPopup}>
                          Login
                        </a>
                      </div>
                      {/* <hr></hr>
                      <div className="profile-link-wrapper">
                        <img
                          src={userPersonalization}
                          className="personalization-img  profile-img"
                          alt="personalization"
                        />
                        <Link to={"/personalization"}>Personalization</Link>
                      </div> */}
                    </div>
                  </div>
                  <div className="up-arrow"></div>
                </div>
                
              )}
              {/* </div> */}
            </li>
          }
        </ul>
        <LoginPage isOpen={isPopupOpen} onClose={closePopup}></LoginPage>
      </nav>
    </>
  );
};

export default Navbar;
