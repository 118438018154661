import React, { createContext, useContext, useRef, useState } from 'react';
import generalChat from "../img/chat/general_chat.png";
import emojiStrip from "emoji-strip";


const ShareWindowContext = createContext();

export const ShareWindowProvider = ({ children }) => {
  const [showShareWindow, setShowShareWindow] = useState(false);
  const [minMaxHeight, setMinMaxHeight] = useState();
  const [chatSession, setChatSession] = useState("");
  const [generatedLink, setGeneratedLink] = useState("");
  const [colorstyl, setColorStyl] = useState("");
  const [zstyle, setZstyle] = useState(false);
  const [selectedOption, setSelectedOption] = useState("option1");

  const [textCount, setTextCount] = useState(0);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [backgroundText, setBackgroundText] = useState("24/7 Health Chat");
  const [backgroundImg, setBackgroundImg] = useState(generalChat);
  const [backgroundreturnColor, setBackgroundreturnColor] = useState("#C1D6F0");
  const [isVoiceSynthesisInProgress, setIsVoiceSynthesisInProgress] =
  useState(false);
  const [currentlyPlayingIndex, setCurrentlyPlayingIndex] = useState(null);
  const [isAutoPlayInProgress, setIsAutoPlayInProgress] = useState(false);
  const [showStopButton, setShowStopButton] = useState(false);
  const [currentAudio, setCurrentAudio] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [disableSendButton, setDisableSendButton] = useState(false);
  const [disableMicButton, setDisableMicButton] = useState(false);
  const [isVoiceSynthesisActive, setIsVoiceSynthesisActive] = useState(false);
  const [isAudioPlaying, setIsAudioPlaying] = useState(false);
  const [currentlyPlayingAudio, setCurrentlyPlayingAudio] = useState(null);
  const [audio, setAudio] = useState(null);
  const [audioData, setAudioData] = useState({});
  const [textResponse, setTextResponse] = useState("");
  const [userHistory, setUserHistory] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [audioVideoChange, setAudioVideoChange] = useState(false);
  const [chatStart, setChatStart] = useState(false);
  const [audioValue, setAudioValue] = useState();
  const [decibelLevel, setDecibelLevel] = useState(0);
  const audioContextRef = useRef(null);
  const analyserRef = useRef(null);


  

  const stopAudio = () => {
    if (audioContextRef.current) {
      audioContextRef.current.close();
      audioContextRef.current = null;
      analyserRef.current = null;
      setDecibelLevel(0);
    }
  };



  const handleStopButtonClick = () => {
    if (isAutoPlayInProgress) {

      // If auto-play is in progress, stop the currently playing auto-play audio
      if (currentAudio) {
        currentAudio.pause();
        currentAudio.currentTime = 0;
      }
      setIsAutoPlayInProgress(false);

      // Update the button to show the play icon
      setShowStopButton(false);
    } else if (audio) {
      // If not in auto-play, stop the currently playing user-initiated audio
      audio.pause();
      audio.currentTime = 0;
      console.log('fgh4')

      // Update the button to show the play icon
      setShowStopButton(false);
    }

    // Reset other relevant states
    setIsSubmitting(false);
    setDisableSendButton(false);
    setDisableMicButton(false);
    setIsVoiceSynthesisActive(false);
    setIsAudioPlaying(false);
    setCurrentlyPlayingIndex(null);
    setCurrentAudio(null);
    console.log('fgh3');
    stopAudio();
    setCurrentlyPlayingAudio(null);
  };

  const playAudio = (text, index) => {
    const textWithoutEmojis = emojiStrip(text);

    if (audioData[textWithoutEmojis]) {
      if (currentlyPlayingAudio) {
        currentlyPlayingAudio.pause();
        currentlyPlayingAudio.currentTime = 0;

        // Reset the currently playing index
        setCurrentlyPlayingIndex(null);
        console.log('fgh')


      }

      // Start playing the newly clicked audio
      const audioElement = new Audio(audioData[textWithoutEmojis]);
      audioElement.preload = "auto";

      audioElement.onplay = () => {
        setAudioValue(audioElement.src)
        setCurrentlyPlayingIndex(index);
        setCurrentlyPlayingAudio(audioElement);
      };

      audioElement.onended = () => {
        setAudioValue(null);
        stopAudio();
        setAudioVideoChange(false);
        setCurrentlyPlayingIndex(null);
        setCurrentlyPlayingAudio(null);
      };


      const audiotest = audioElement.src

      if (!audioContextRef.current) {
        audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
        analyserRef.current = audioContextRef.current.createAnalyser();
        analyserRef.current.fftSize = 8192; // Increase FFT size for higher frequency resolution
        // console.log(first)
        const audioElement = new Audio();
        audioElement.src = audiotest
        const source = audioContextRef.current.createMediaElementSource(audioElement);
        source.connect(analyserRef.current);
        analyserRef.current.connect(audioContextRef.current.destination);

        const bufferLength = analyserRef.current.frequencyBinCount;
        const dataArray = new Uint8Array(bufferLength);

        const calculateDecibel = () => {
          if (analyserRef.current) {
            analyserRef.current.getByteFrequencyData(dataArray);
            let rms = 0;
            for (let i = 0; i < bufferLength; i++) {
              rms += dataArray[i] * dataArray[i];
            }
            rms = Math.sqrt(rms / bufferLength);
            const decibel = 20 * Math.log10(rms);
            setDecibelLevel(decibel);
            console.log(decibel)
            if (decibel < 30) {
              setAudioVideoChange(false);
            } 
            
            if (decibel > 30) {
              setAudioVideoChange(true);
              console.log('ok')
            }
            
            requestAnimationFrame(calculateDecibel);
          }
        };

        audioElement.addEventListener('ended', () => {
          stopAudio();
        });

        if (window.innerWidth > 600) {
          audioElement.play().catch(error => {
            console.error('Failed to play audio:', error);
            stopAudio();
          });
        }

        calculateDecibel();
      }
      audioElement.play();
      if (window.innerWidth > 600) {
      audioElement.volume = 0;
    }
      setAudio(audioElement);
    }
  };


  
  return (
    <ShareWindowContext.Provider value={{ audioVideoChange, setAudioVideoChange,showDropdown, zstyle, setZstyle, selectedOption, setSelectedOption ,setShowDropdown, userHistory, setUserHistory, textResponse, setTextResponse, playAudio, audioData, setAudioData, handleStopButtonClick, audio, setAudio, currentlyPlayingAudio, setCurrentlyPlayingAudio, isAudioPlaying, setIsAudioPlaying, isVoiceSynthesisActive, setIsVoiceSynthesisActive, disableMicButton, setDisableMicButton, disableSendButton, setDisableSendButton, isSubmitting, setIsSubmitting, currentAudio, setCurrentAudio, showStopButton, setShowStopButton, isAutoPlayInProgress, setIsAutoPlayInProgress, currentlyPlayingIndex, setCurrentlyPlayingIndex, isVoiceSynthesisInProgress, setIsVoiceSynthesisInProgress, backgroundreturnColor, setBackgroundreturnColor, backgroundImg, setBackgroundImg, backgroundText, setBackgroundText,isButtonDisabled, setIsButtonDisabled, textCount, setTextCount, showShareWindow, setShowShareWindow, chatSession, setChatSession, generatedLink, setGeneratedLink, minMaxHeight, setMinMaxHeight }}>
      {children}
    </ShareWindowContext.Provider>
  );
};

export const useShareWindow = () => {
  const context = useContext(ShareWindowContext);
  if (!context) {
    throw new Error('useShareWindow must be used within a ShareWindowProvider');
  }
  return context;
};
