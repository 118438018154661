import React, { useEffect, useRef, useState } from "react";
import Form from "../Form/Form";
import { Link, useParams } from "react-router-dom";
import "./MainLayout.css";
import "../Start/Start.css";
import Navbar from "../Navbar/Navbar";
import greetQuestion from "../../img/chat/greet-question.png";
import mornning from "../../img/chat/morning_icon.png";
import afternoon from "../../img/chat/afternoon_icon.png";
import evening from "../../img/chat/evening_icon.png";
import night from "../../img/chat/night_icon.png";
import knightingale_background_video from "../../videos/chat/idle.mp4";
import knightingale_background_video_mobile from "../../videos/chat/idle.mp4";
import knightingale_character_loop from "../../videos/chat/idle.mp4";
import careReminderMp4t from "../../videos/chat/lip_synced.mp4";
import knightingale_character_loop_poster from "../../img/chat/knightingale_character_loop_poster.jpg";
import { useShareWindow } from "../../context/ShareWindowContext.js";
import ShareWindow from "../Sharewindow/ShareWindow.js";
import axios from "axios";
import share from "../../img/chat/share.svg";
import descktop_poster from "../../img/chat/knightingale_background_thumbnail.jpg";
import knightingale_background_video_mobile_poster from "../../img/chat/knightingale_background_video_mobile_poster.jpg";


const axiosInstance = axios.create();
const axiosVoiceRecognitionInstance = axios.create();

const SET_NICKNAME = "https://knightingale.ai:5001/get-user-nickname";

function Test(props) {
  const buttonRef = useRef(null);
  const [backgroundColor, setBackgroundColor] = useState("#f0f2f6");
  const [backgroundMp, setBackgroundMp] = useState(knightingale_background_video);
  // for below change 24/7 chat only
  const [centerHome, setCenterHome] = useState(false);
  const [isBack, setIsBack] = useState(false);

  const [returnBar, setReturnBar] = useState("option1");
  const [nickname, setNickname] = useState("");
  const [divInnerWidth, setDivInnerWidth] = useState(45);
  const { session: urlSession } = useParams();
  const [greeting, setGreeting] = useState("");
  const [image, setImage] = useState("");
  const [showTooltip, setShowTooltip] = useState(false);
  const [shareOpacity, setShareOpacity] = useState(false);
  const [hoverTimeout, setHoverTimeout] = useState(null);
  const caldivwidth = useRef(null);

  const {
    showShareWindow,
    setShowShareWindow,
    chatSession,
    setChatSession,
    isButtonDisabled,
    backgroundText, setBackgroundText
    , backgroundImg, setBackgroundImg, backgroundreturnColor, setBackgroundreturnColor, zstyle, setZstyle, setSelectedOption,audioVideoChange
  } = useShareWindow();




  console.log(isButtonDisabled);

  const uniqueLink = `https://home.knightingale.ai/knwidget/share/${chatSession}`;

  const [conversationHistory, setConversationHistory] = useState([]);

  useEffect(() => {
    const videoElements = document.querySelectorAll(".start-video");

    const handleResize = () => {
      if (window.innerWidth < 767) {
        videoElements.forEach((video) => {
          video.src = knightingale_background_video_mobile;
          video.poster = knightingale_background_video_mobile_poster;
        });
      } else {
        videoElements.forEach((video) => {
          video.src = backgroundMp;
          video.poster = descktop_poster;
        });
      }
    };

    // Initial setup
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [knightingale_background_video_mobile, knightingale_background_video_mobile_poster, backgroundMp, descktop_poster]);


  useEffect(() => {
    // Fetch user nickname from the backend
    fetch(SET_NICKNAME)
      .then((response) => response.json())
      .then((data) => {
        setNickname(data.nickname);
      })
      .catch((error) => console.error("Error fetching user nickname:", error));
  }, []);

  useEffect(() => {
    buttonRef.current.click();
  }, []);

  const videoRef = useRef(null);

  const handlePlayClick = () => {
    const video = videoRef.current;
    if (video) {
      video.play().catch((error) => {
        console.error("Autoplay error:", error);
      });
    }
  };



  useEffect(() => {
    const updateGreetingAndImage = () => {
      const currentHour = new Date().getHours();

      let newGreeting, newImage;

      if (currentHour >= 5 && currentHour < 12) {
        newGreeting = "Good Morning";
        newImage = mornning;
      } else if (currentHour >= 12 && currentHour < 17) {
        newGreeting = "Good Afternoon";
        newImage = afternoon;
      } else if (currentHour >= 17 && currentHour < 21) {
        newGreeting = "Good Evening";
        newImage = evening;
      } else {
        newGreeting = "Good Evening";
        newImage = night;
      }

      setGreeting(newGreeting);
      setImage(newImage);
    };

    updateGreetingAndImage(); // Initial update

    // Update every minute
    const intervalId = setInterval(updateGreetingAndImage, 60000);

    return () => clearInterval(intervalId);
  }, []); // Empty dependency array to run the effect only once

  const divStyle = {
    backgroundColor: backgroundColor,
  };

  const backgroundOpacity = .5;

  const returnStyle = {
    backgroundColor: `rgba(${parseInt(
      backgroundreturnColor.slice(1, 3),
      16
    )}, ${parseInt(backgroundreturnColor.slice(3, 5), 16)}, ${parseInt(
      backgroundreturnColor.slice(5, 7),
      16
    )}, ${backgroundOpacity})`,
    borderColor: backgroundreturnColor,
  };

  const handleRefresh = () => {
    window.location.reload();
  };

  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    // Set loaded to true after the component has mounted
    setLoaded(true);
  }, []);

  const [screenHeight, setScreenHeight] = useState(window.innerHeight);

  useEffect(() => {
    const updateScreenHeight = () => {
      const newHeight = Math.max(648, Math.min(1500, window.innerHeight));
      setScreenHeight(newHeight);
    };

    // Update screen height when the window is resized
    window.addEventListener("resize", updateScreenHeight);

    // Initial update
    updateScreenHeight();

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", updateScreenHeight);
    };
  }, []);

  let backClicked = false;

  if (!centerHome) {
    window.addEventListener("popstate", function (event) {
      if (!backClicked) {
        backClicked = true;
      } else {
        window.addEventListener("hashchange", function () {
          console.log("Fragment identifier or URL changed");
          window.location.reload();
        });
      }
    });
  }

  useEffect(() => {
    if (centerHome === false) {
      const handleClick = () => {
        console.log("Auto click event triggered!");
      };

      const autoclickElement = document.getElementById("autoclick");

      if (autoclickElement) {
        autoclickElement.addEventListener("click", handleClick);
        autoclickElement.click();
        return () => {
          autoclickElement.removeEventListener("click", handleClick);
        };
      }
    }
  }, [centerHome]);


  useEffect(() => {
    if (urlSession) {
      // If a session ID is present in the URL, use it
      setChatSession(urlSession);

      // Fetch conversation history for the current chat session
      axios
        .get(`https://knightingale.ai:5001/api/conversation-history/${urlSession}`)
        .then((response) => {
          // Update conversation history state
          setConversationHistory(response.data);
        })
        .catch((error) => {
          console.error("Error fetching conversation history:", error);
        });
    } else {
      // If no session ID in the URL, fetch a new session ID when the component mounts
      axios
        .get("https://knightingale.ai:5001/api/generate-session")
        .then((response) => {
          const newChatSession = response.data.chatSession;
          setChatSession(newChatSession);
          // Store the new session ID in localStorage
          localStorage.setItem("chatSession", newChatSession);
        })
        .catch((error) => {
          console.error("Error generating session:", error);
        });
    }
  }, [urlSession]);


  const handleMouseEnterShare = () => {
    setZstyle(true)
    const timeout = setTimeout(() => {
      setShowTooltip(true);
    }, 2000); // 2 seconds
    setHoverTimeout(timeout);
  };

  const handleMouseLeaveShare = () => {
    clearTimeout(hoverTimeout);
    setShowTooltip(false);
    setZstyle(false)
  };

  useEffect(() => {
    // Function to calculate and log the width of the div
    const calculateDivWidth = () => {
      if (caldivwidth.current) {
        const width = caldivwidth.current.offsetWidth;
        console.log("Width of div:", width);
        if (width === 0) {
          setDivInnerWidth(45)
        } else {

          setDivInnerWidth(205 - width)
        }
      }
    };

    // Call the function initially and whenever the component re-renders
    calculateDivWidth();

    // Add event listeners or any other cleanup if needed
    // For example, if the width needs to be recalculated on window resize:
    window.addEventListener('resize', calculateDivWidth);

    // Cleanup function
    return () => {
      window.removeEventListener('resize', calculateDivWidth);
    };
  }, [setSelectedOption]);


  console.log(audioVideoChange)
  return (
    <div
      className="main-three-colum-layout-wrapper start-bg-color"
      style={divStyle}
    >
      <div className="main-three-colum-layout-container">
        <div
          className="main-three-colum-layout start-main-three-colum-layout"
          style={divStyle}
        >
          <video
            ref={videoRef}
            className="new-mobile-background-video start-video"
            // onLoadedData={handleVideoLoad}
            playsInline
            muted
            loop
          />

          <button
            ref={buttonRef}
            onClick={() => handlePlayClick()}
            style={{ display: "none" }}
          ></button>
          {/* <div className="left-column start-left-column">
            <div className="video-wrapper">
              <div className="new-background-video-container-chat videoclass">
                <video
                  className=""
                  playsInline
                  autoPlay
                  loop
                  muted
                  poster={knightingale_character_loop_poster}
                  src={knightingale_background_video}
                /> */}
          {/* <Avator/> */}
          {/* <model-viewer
                  src="../../knightingale_3d.glb"
                  ar
                  ar-modes="webxr scene-viewer quick-look"
                  camera-controls
                  tone-mapping="commerce"
                  poster="poster.webp"
                  shadow-intensity="1"
                  autoplay
                  camera-orbit="-346.9deg 86.24deg 6.91m"
                  field-of-view="17.6deg"
                  auto-rotate
                  min-camera-orbit="56deg 80deg 7.699m"
                  max-camera-orbit="180deg 80deg auto"
                  min-field-of-view="15.4deg"
                >
                  <div class="progress-bar hide" slot="progress-bar">
                    <div class="update-bar"></div>
                  </div>
                </model-viewer> */}
          {/* </div>
            </div>
          </div> */}
          {<a href="#" id="autoclick"></a>}
          <div
          // below use to 24/7 chat
          // className="center-column start-central-column "
          className="center-column  " 
            // style={{
            //   ...(centerHome
            //     ? {
            //       marginTop: `${screenHeight > 760
            //         ? 120
            //         : screenHeight > 600
            //           ? (screenHeight - 600) * 0.75
            //           : 0
            //         }px`,
            //     }
            //     : {}),
            // }}
          >


            <div className="left-column start-left-column">
              {/* <div className="video-wrapper">
                <div className="new-background-video-container-chat videoclass">
                  
                    <div
                    style={{
                      position: `${audioVideoChange ? 'static' : 'absolute'}`,
                      width: '88%'

                    }}
                  >

                    <video
                      className={`new-background-video-container-chat-video `}
                      style={{
                        // opacity: `${audioVideoChange ? '0' : '1'}`, left: '-55%',
                        // zIndex: '-10',
                        // position: `${audioVideoChange ? 'relative' : 'static'} `
                      }}
                      playsInline
                      autoPlay
                      loop
                      muted
                      poster={knightingale_character_loop_poster}
                      src={knightingale_character_loop}
                    />
                  </div>
                  <div
                    style={{
                      position: `${audioVideoChange ? 'absolute' : 'static'}`,
                      opacity: `${audioVideoChange ? '1' : '0'}`,
                      transition: 'opacity 200ms ease',
                      width: '88%'
                    }}
                  >

                    <video
                      className="new-background-video-container-chat-video"
                      style={{
                        // left: '-65%',
                        // zIndex: '-10',
                        // position: `${audioVideoChange ? 'relative' : 'static'} `, opacity: `${audioVideoChange ? '1' : '0'} `
                      }
                      }
                     laysInline
                      autoPlay
                      loop
                      muted
                      poster={knightingale_character_loop_poster}
                      src={careReminderMp4t}
                    />
                  </div>
                </div>
              </div> */}
            </div>




            <div className="desk_home_video_wrapper">
              <div className="desk_home_video_container">
                <div className="new-background-video-container-chat videoclass">
                <div
                    style={{
                      position: `${audioVideoChange ? 'static' : 'absolute'}`,
                      width: '78%'

                    }}
                  >

                    <video
                      className={`new-background-video-container-chat-video `}
                      style={{
                        // opacity: `${audioVideoChange ? '0' : '1'}`, left: '-55%',
                        // zIndex: '-10',
                        // position: `${audioVideoChange ? 'relative' : 'static'} `
                      }}
                      playsInline
                      autoPlay
                      loop
                      muted
                      poster={knightingale_character_loop_poster}
                      src={knightingale_character_loop}
                    />
                  </div>
                  <div
                    style={{
                      position: `${audioVideoChange ? 'absolute' : 'static'}`,
                      opacity: `${audioVideoChange ? '1' : '0'}`,
                      transition: 'opacity 200ms ease',
                      width: '78%'
                    }}
                  >

                    <video
                      className="new-background-video-container-chat-video"
                      style={{
                        // left: '-65%',
                        // zIndex: '-10',
                        // position: `${audioVideoChange ? 'relative' : 'static'} `, opacity: `${audioVideoChange ? '1' : '0'} `
                      }
                      }
                     laysInline
                      autoPlay
                      loop
                      muted
                      poster={knightingale_character_loop_poster}
                      src={careReminderMp4t}
                    />
                  </div>
                </div>
              </div>
            </div>


            {/* {returnBar && (
              <div
                className="central-navbar-backside-color start-central-navbar-backside-color"
                style={returnStyle}
              ></div>
            )} */}
            {/* {returnBar && (
              <div
                className="central-column-bbtn-wrapper start-central-column-bbtn-wrapper"
                style={zstyle ? { zIndex: '10000' } : { zIndex: '1000' }}

              >
                <div className="central-column-bbtn-inner"
                  style={returnStyle}
                >
                  <div className="central-column-bbtn">
                    <div className="central-column-btn-middle-wrapper" >
                      <div className="central-column-btn-middle-container" ref={caldivwidth}>
                        {backgroundImg && <img
                          src={backgroundImg}
                          className="central-column-btn-middle-img"
                          alt=""
                        />}
                        <span>{backgroundText}</span>
                      </div>
                      <div style={{ width: `${divInnerWidth}px` }}>

                      </div>
                    </div>
                    <div className="knightingale_avatar_bar_wrapper">
                      <div className="knightingale_avatar_bar">
                      </div>
                    </div>
                    <div className="sharebtn-wrapper start-sharebtn-wrapper">
                      <div className="sharebtn-inner">
                        <div className="sharebtn"
                          onMouseEnter={handleMouseEnterShare}
                          onMouseLeave={handleMouseLeaveShare}
                        >

                          <button
                            className="sharebtn-img tooltip"
                            onClick={() => setShowShareWindow(true)}
                            disabled={isButtonDisabled}
                            onMouseEnter={() => setShareOpacity(true)}
                            onMouseLeave={() => setShareOpacity(false)}
                            style={{
                              opacity: isButtonDisabled ? "0.5" : (shareOpacity ? "1" : ".7")
                            }}
                          >
                            <img
                              src={share}
                              alt="share"
                              disabled={isButtonDisabled}
                              style={isButtonDisabled ? { opacity: "0.5" } : { opacity: "1" }}
                            />
                            Share

                            {showTooltip && <div class="tooltip">
                              <span class="tooltiptext">Share</span>
                            </div>}
                          </button>

                        </div>
                      </div>
                    </div>{" "}
                  </div>
                </div>
              </div>
            )} */}


      {/* <div className="central-column-btn-right"></div>
      </div>
              </div>
            )}         */}

  {/* // for below change 24/7 chat only */}

            {/* <div
              className={`central-greeting-wrapper start-central-greeting-wrapper app 
              ${loaded ? "loaded" : ""
                }
                
                `}
            > */}

            <div
              className={`central-greeting-wrapper start-central-greeting-wrapper app 
             
                
                `}
            >

              {/* <div>
                <div className="central-greeting-container">
                  <div className="central-greeting-user">
                    <img src={image} className="" alt="Sun" />
                    <span className="central-greeting-text">
                      {greeting}{" "}
                      <span className="central-greeting-user-name"></span>
                    </span>
                  </div>
                  <div className="central-greeting-question">
                    <span className="central-greeting-ques">
                      How are you feeling today?{" "}
                    </span>
                    <img src={greetQuestion} className="" alt="Greet Question" />
                  </div>
                  <div
                    className={`central-greeting-down-arrow ${loaded ? "central-greeting-down-arrow-animate" : ""
                      }`}
                  >
                    <span className=""></span>
                  </div>
                </div>
              </div> */}
            </div>
            {/* <div className="start-cental-video-wrapper start-central-video-display">
              <div className="video-wrapper videoclass" id="yourVideoDivId">
                <div className="new-background-video-container videoclass">
                  <video
                    className="start-video"
                    id="start_video"
                    playsInline
                    autoPlay
                    loop
                    muted
                  />
                </div>
              </div>
            </div> */}
            <Form
              setBackgroundColor={setBackgroundColor}
              setBackgroundImg={setBackgroundImg}
              setBackgroundText={setBackgroundText}
              setBackgroundMp={setBackgroundMp}
              setBackgroundreturnColor={setBackgroundreturnColor}
              setReturnBar={setReturnBar}
              backgroundMp={backgroundMp}
              setCenterHome={setCenterHome}
            />
          </div>

          <div className="right-column">
            <Navbar setCenterHome={setCenterHome} />
          </div>
        </div>
        {showShareWindow && (
          <ShareWindow
            generatedLink={uniqueLink}
            onClose={() => setShowShareWindow(false)}
          />
        )}
      </div>
    </div>
  );
}

export default Test;
