import React, { useState, useEffect, useRef } from "react";
import ReactDOMServer from "react-dom/server";
import axios from "axios";
import voiceLogo from "../../img/mic_icon.svg";
import image2 from "../../img/health-care.png";
import image3 from "../../img/doctor2.png";
import image4 from "../../img/Jesus_Background.jpg";
import image5 from "../../img/med.jpg";
import video1 from "../../img/knightingale.mp4";
import notificationSound from "../../sounds/notification sound.mp3";
import emojiStrip from "emoji-strip";
import symptomChecker from "../../img/chat/symptom-checker.png";
import reportAnalyzer from "../../img/chat/report-analyzer.png";
import calorieCounter from "../../img/chat/calorie-counter.png";
import wellnessCouch from "../../img/chat/wellness-coach.png";
import sleepHelper from "../../img/chat/sleep-helper.png";
import workoutPlanner from "../../img/chat/workout-planner.png";
import womenHealth from "../../img/chat/women-health.png";
import share from "../../img/chat/share.png";
import careReminder from "../../img/chat/care-reminder.png";
import mixpanel from 'mixpanel-browser';
import { useShareWindow } from "../../context/ShareWindowContext.js";
import symptomCheckerMp4 from "../../videos/chat/knightingale_character.mp4";
import reportAnalyzerMp4 from "../../videos/chat/knightingale_character.mp4";
import calorieCounterMp4 from "../../videos/chat/knightingale_character.mp4";
import wellnessCouchMp4 from "../../videos/chat/knightingale_character.mp4";
import sleepHelperMp4 from "../../videos/chat/knightingale_character.mp4";
import workoutPlannerMp4 from "../../videos/chat/knightingale_character.mp4";
import investorRelationsMp4 from "../../videos/chat/knightingale_character.mp4";
import careReminderMp4 from "../../videos/chat/knightingale_character.mp4";
import "./Form.css";
import Chat from "../Chat/Chat";
import ChatInput from "../ChatInput/ChatInput";
import { useParams } from "react-router-dom";

/*for the localhost:
const AI_API_URL = 'http://127.0.0.1:5001/api/ai';
const VOICE_RECOGNITION_API_URL = 'http://127.0.0.1:5001/api/voice-recognition';

for the server:
const AI_API_URL = 'https://knightingale.ai:5001/api/ai';
const VOICE_RECOGNITION_API_URL = 'https://knightingale.ai:5001/api/voice-recognition';
*/
const AI_API_URL = "https://knightingale.ai:5001/api/ai";
const VOICE_RECOGNITION_API_URL =
  "https://knightingale.ai:5001/api/voice-recognition";
const AUDIO_SYNTHESIS_API_URL =
  "https://knightingale.ai:5001/api/convert-text-to-speech";
const AUTOCOMPLETE_API_URL = "https://knightingale.ai:5001/api/autocomplete";
const VISION = "https://knightingale.ai:5001/upload-image";
const CONVERSATION_STARTER = "https://knightingale.ai:5001/conversation-starter";
const CONV_RESPONSE = "https://knightingale.ai:5001/chat-starter-response";

const axiosInstance = axios.create();
const axiosVoiceRecognitionInstance = axios.create();

mixpanel.init('f3bc0a936d16d0fd8b1da0c66e4ae0b0', { debug: true, track_pageview: true, persistence: 'localStorage' });


function Form({
  setBackgroundColor,
  setBackgroundText,
  setBackgroundImg,
  setBackgroundMp,
  setBackgroundreturnColor,
  setReturnBar,
  backgroundMp,
  setCenterHome,
}) {
  const [input, setInput] = useState("");
  // const [selectedOption, setSelectedOption] = useState("option1");
  const [backgroundImage, setBackgroundImage] = useState(image5);
  const [backgroundVideo, setBackgroundVideo] = useState(video1);
  const [showOptions, setShowOptions] = useState(false);
  const [isVoiceRecognitionActive, setIsVoiceRecognitionActive] =
    useState(false);
  const [showLoadingIndicator, setShowLoadingIndicator] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [audioUrl, setAudioUrl] = useState(null);
  const [isFirstResponse, setIsFirstResponse] = useState(true);
  const [isRecording, setIsRecording] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [responseTexts, setResponseTexts] = useState(["", "", "", ""]);
  const [isSelectSuggest, setSelectSuggest] = useState(true);
  const [imgSuggession, setImgSuggession] = useState();
  const [suggessionTitle, setSuggessionTitle] = useState("Welcome to Your Journey Towards Wellness!");
  const [boxSuggession, setBoxSuggession] = useState("#EAEDF1");
  const [conversationHistory, setConversationHistory] = useState([]);
  const { session: urlSession } = useParams();
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const { currentlyPlayingIndex, setCurrentlyPlayingIndex,setMinMaxHeight,minMaxHeight,
    showShareWindow, setShowShareWindow, chatSession, setChatSession,
    generatedLink, setGeneratedLink, isVoiceSynthesisInProgress, setIsVoiceSynthesisInProgress,
    isAutoPlayInProgress, setIsAutoPlayInProgress, showStopButton, setShowStopButton, isSubmitting, setIsSubmitting,
    currentAudio, setCurrentAudio, disableSendButton, setDisableSendButton, 
    currentlyPlayingAudio, setCurrentlyPlayingAudio, disableMicButton, setDisableMicButton, isVoiceSynthesisActive, 
    audio, setAudio, setIsVoiceSynthesisActive, isAudioPlaying, setIsAudioPlaying, handleStopButtonClick,  
    audioData, setAudioData, playAudio,textResponse, setTextResponse, userHistory, setUserHistory, showDropdown, setShowDropdown, selectedOption, setSelectedOption
   } = useShareWindow();
  // const [showShareWindow, setShowShareWindow] = useState(false);
  // const [chatSession, setChatSession] = useState("");

  const textCount = input.replace(/\s+/g, '').length;
  console.log(textCount)

  const textareaRef = useRef(null);

  const dropdownRef = useRef(null);


  
  useEffect(() => {
    adjustTextareaHeight();
    
  }, []);
  console.log(minMaxHeight)


  

  console.log(minMaxHeight)

  useEffect(() => {
    if (urlSession) {
      setChatSession(urlSession);

      axios
        .get(`https://knightingale.ai:5001/api/conversation-history/${urlSession}`)
        .then((response) => {
          setConversationHistory(response.data);
        })
        .catch((error) => {
          console.error("Error fetching conversation history:", error);
        });
    } else {
      axios
        .get("https://knightingale.ai:5001/api/generate-session")
        .then((response) => {
          const newChatSession = response.data.chatSession;
          setChatSession(newChatSession);
          localStorage.setItem("chatSession", newChatSession);
        })
        .catch((error) => {
          console.error("Error generating session:", error);
        });
    }
  }, [urlSession]); // Run the effect when the URL session changes

  // Construct the unique link for the current session
  const uniqueLink = `https://home.knightingale.ai/knwidget/share/${chatSession}`;



  useEffect(() => {
    adjustTextareaHeight();
    
  }, [input]);


  // ----------------------------------------------------------------below using to 24 / 7 chat--------------------------------------------------------------------------

  // useEffect(() => {
  //   if (!(selectedOption === "option1")) {
  //     removeClasses();
  //   }
  // }, [selectedOption, backgroundMp]);

  useEffect(() => {
    if ((selectedOption === "option1")) {
      removeClasses();
    }
  }, []);

  useEffect(() => {
    changeSelectedOption("option1");
  }, []);


  const handleSubmit = async (e) => {
    temporarySendMessege(input);

    setInput("");

    e.preventDefault();

    if (!input.trim()) {
      return;
    }

    try {
      setIsSubmitting(true);
      setDisableSendButton(true);
      setDisableMicButton(true);
      setIsVoiceSynthesisActive(true);
      setShowLoadingIndicator(true);
      removeClasses();
      setSelectSuggest(false);
      mixpanel.track('Query Sumbitted', { 'Button Type': 'querysumbitted' })

      // Prepare the conversation history with system and user messages
      const updatedConversation = [
        ...userHistory.flatMap((entry) => [
          { role: "user", content: entry.input },
          { role: "assistant", content: entry.response },
        ]),
        { role: "user", content: input },
      ];
      const res = await axiosInstance.post(AI_API_URL, {
        conversations: updatedConversation,
        selectedOption,
        chatSession,
      });

      const textResponse = res.data.completion;

      removeElementById();

      playNotificationSound();
      const textResponseWithoutEmojis = emojiStrip(textResponse);

      setTextResponse(textResponseWithoutEmojis);
      const styledHtmlContent = res.data.completion.replace(
        regex,
        '<span class="bold-text">$1</span>'
      );
      const newEntry = {
        input,
        response: styledHtmlContent,
      };
      setUserHistory([...userHistory, newEntry]);

      triggerVoiceSynthesis(textResponseWithoutEmojis);

      setIsSubmitting(false);
      setDisableSendButton(false);
      setDisableMicButton(false);
      setIsVoiceSynthesisActive(false);
      setShowLoadingIndicator(false);
    } catch (error) {
      console.error(error);
      setIsSubmitting(false);
      setDisableSendButton(false);
      setDisableMicButton(false);
      setIsVoiceSynthesisActive(false);
      setShowLoadingIndicator(false);
    }
  };

  const removeElementById = () => {
    const elementToRemove = document.getElementById("temp-q-msg");

    if (elementToRemove) {
      elementToRemove.remove();
    } else {
      console.log("Element not found.");
    }
  };

  const temporarySendMessege = (transcription, input) => {
    const parentElement = document.querySelector(".q-and-a-container");
    const childElements = parentElement.querySelectorAll(".main-q-a-box");
    const lastChild = childElements[childElements.length - 1];
    const index = childElements.length;

    const newElement = (
      <div id="temp-q-msg" className="main-q-a-box" key={index}>
        <div className="client-msg-bubble">
          <h2 className="client-questions">
            {transcription}
            {input}
          </h2>
        </div>
      </div>
    );

    const htmlString = ReactDOMServer.renderToStaticMarkup(newElement);

    if (index === 0) {
      parentElement.insertAdjacentHTML("beforeend", htmlString);
    } else if (index > 0) {
      lastChild.insertAdjacentHTML("afterend", htmlString);
    }
  };

  const triggerVoiceSynthesis = async (textResponse, isAutoPlay) => {
    try {
      setIsVoiceSynthesisInProgress(true);

      const audioRes = await axiosInstance.post(AUDIO_SYNTHESIS_API_URL, {
        text: textResponse,
      });

      // Save the audio URL for later playback along with the corresponding text
      const audioUrl = audioRes.data.audioUrl;
      setAudioData((prevAudioData) => ({
        ...prevAudioData,
        [textResponse]: audioUrl,
      }));

      setIsVoiceSynthesisInProgress(false);
    } catch (error) {
      console.error(error);
      setIsVoiceSynthesisInProgress(false);
    }
  };

  const handleMicButtonClick = async () => {
    if (!isRecording && !isSubmitting && !isAutoPlayInProgress) {
      setIsRecording(true);
      mixpanel.track('Voice Recognition Used', { 'Button Type': 'voicerecognition' })
      try {
        let stream;

        // Check if it's iOS
        if (navigator.userAgent.match(/(iPod|iPhone|iPad)/)) {
          // Use the WebKit API for iOS
          stream = await navigator.mediaDevices.getUserMedia({
            audio: {
              channelCount: 1,
              echoCancellation: true,
              sampleRate: 44100,
            },
          });
        } else {
          // Use the standard API for other browsers
          stream = await navigator.mediaDevices.getUserMedia({
            audio: true,
          });
        }

        const recorder = new MediaRecorder(stream);
        setMediaRecorder(recorder);

        const chunks = [];

        recorder.ondataavailable = (event) => {
          if (event.data.size > 0) {
            chunks.push(event.data);
          }
        };

        recorder.onstop = () => {
          const audioBlob = new Blob(chunks, { type: "audio/wav" });
          sendAudioToBackend(audioBlob);
          setIsRecording(false);
          setMediaRecorder(null); // Reset mediaRecorder
        };

        recorder.start();
      } catch (error) {
        console.error("Error accessing microphone:", error);
        setIsRecording(false);
      }
    } else if (mediaRecorder) {
      // Check if mediaRecorder is not null
      mediaRecorder.stop();
      setMediaRecorder(null); // Reset mediaRecorder
      setIsRecording(false); // Reset isRecording
    }
  };

  const sendAudioToBackend = async (audioBlob) => {
    try {
      const formData = new FormData();
      formData.append("audioFile", audioBlob);

      const res = await axiosVoiceRecognitionInstance.post(
        VOICE_RECOGNITION_API_URL,
        formData
      );
      const { transcription } = res.data;

      temporarySendMessege(transcription);

      setShowLoadingIndicator(true);

      if (transcription.trim().length > 0) {
        removeClasses();
        const updatedConversation = [
          ...userHistory.flatMap((entry) => [
            { role: "user", content: entry.input },
            { role: "assistant", content: entry.response },
          ]),
          { role: "user", content: transcription },
        ];

        const openaiRes = await axiosInstance.post(AI_API_URL, {
          conversations: updatedConversation,
          selectedOption,
          chatSession,
        });
        removeElementById();

        playNotificationSound();
        const textResponseWithoutEmojis = emojiStrip(openaiRes.data.completion);
        setTextResponse(textResponseWithoutEmojis);

        const styledHtmlContent = openaiRes.data.completion.replace(
          regex,
          '<span class="bold-text">$1</span>'
        );
        const newEntry = {
          input: transcription,
          response: styledHtmlContent,
        };
        setUserHistory([...userHistory, newEntry]);
        setInput("");

        setDisableSendButton(false);
        setDisableMicButton(false);

        triggerVoiceSynthesis(textResponseWithoutEmojis);

        setShowLoadingIndicator(false);
      } else {
        console.log("Empty transcription. Not sending to OpenAI.");
        setShowLoadingIndicator(false);
      }
    } catch (error) {
      console.error(error);
      setShowLoadingIndicator(false);
      setDisableSendButton(false);
      setDisableMicButton(false);
    }
  };

  const handleOptionChange = (e) => {
    const option = e.target.value;
    setSelectedOption(option);
    setInput("");
    // updateBackgroundImage(option);
  };

  //autocompletion fetching
  /*const fetchAutocompleteSuggestions = async (query) => {
    try {
      const response = await axios.get(
        `${AUTOCOMPLETE_API_URL}?query=${query}`
      );
      const suggestions = response.data.suggestions;
      setSuggestions(suggestions);
    } catch (error) {
      console.error("Error fetching autocomplete suggestions:", error);
    }
  };*/

  const handleInputChange = (e) => {
    const newInput = e.target.value;
    setInput(newInput);
    //fetchAutocompleteSuggestions(newInput);

    // Update showDropdown state to control visibility
    setShowDropdown(newInput !== "");

    adjustTextareaHeight();
    // Opacity change there is text in the textarea
  };

  const getLetterCount = () => {
    return input.length;
  };


  const getStyles = () => {
    const opacity = input === "" ? 0.6 : 1;
    const boxShadow = input === "" ? "none" : "";

    return { opacity, boxShadow };
  };

  //button submit active after add
  const getSubmit = () => {
    const boxShadow = input === "" ? "none" : "";
    const display = input === "" ? "none" : "block";

    return { boxShadow, display };
  };
  const getVoice = () => {
    const display = input === "" ? "Block" : "none";
    return { display };
  };
  const getText = () => {
    const background = input === "" ? "" : "#fff";
    return { background };
  };

  //suggestions selector
  const handleSuggestionClick = (selectedSuggestion) => {
    setInput(selectedSuggestion);
    setSuggestions([]); // Clear the suggestions list
  };



  const [disableFunction, setDisableFunction] = useState(false);

  const handleKeyDown = (e) => {
    if (textCount > 0) {

      if (!disableFunction && e.key === "Enter") {
        handleSubmit(e);
      }
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setDisableFunction(window.innerWidth < 767);
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const playNotificationSound = () => {
    const audio = new Audio(notificationSound);

    audio.play();
    setSelectedImage(null);
  };

  const adjustTextareaHeight = () => {
    const textarea = textareaRef.current;
    textarea.style.height = "45px";
    const newHeight = Math.min(textarea.scrollHeight, 130);
    // textarea.style.height = `${newHeight}px`;
    console.log(newHeight)
    var screenWidth = window.innerWidth;
    if (screenWidth < 767) {
      setMinMaxHeight(newHeight - 57 + 40); // Subtract 17 instead of 57 for better fit
  } else {
      setMinMaxHeight(newHeight - 44 + 50); // Subtract 24 instead of 44 for better fit
  }
  };

  const handleKeyUp = (e) => {
    if (e.key === "Backspace") {
      adjustTextareaHeight();
    }
  };

  const getPlaceholderText = () => {
    switch (selectedOption) {
      case "option1":
        return "How can I help you stay healthy?";
      case "option2":
        return "What are your symptoms?";
      case "option3":
        return "Enter your health report";
      case "option4":
        return "Ask me about healthy eating";
      case "option6":
        return "How can I help you be mindful?";
      case "option7":
        return "How can I help you sleep better?";
      case "option8":
        return "How can I help you stay fit?";
      case "option9":
        return "Investors, ask me anything?";
      case "option11":
        return "How can I help reduce stress?";
      default:
        return "Enter your input";
    }
  };


  useEffect(() => {
    adjustTextareaHeight();
    window.addEventListener("click", handleWindowClick);

    const playResponseVoice = async () => {
      try {
        if (textResponse) {
          // Play the audio URL if it exists
          if (audioData[textResponse]) {
            /*const newIndex = userHistory.length; // Get the current index
            setCurrentlyPlayingIndex(newIndex); // Set currentlyPlayingIndex
            const audioElement = new Audio(audioData[textResponse]);
            setCurrentAudio(audioElement); // Set currentAudio
            setIsAudioPlaying(true); // Set isAudioPlaying to true
            setIsAutoPlayInProgress(true); // Set auto-play in progress
            audioElement.play(); // Play the audio
            audioElement.onended = () => {
              setIsAutoPlayInProgress(false); // Reset auto-play in progress when done
              setIsAudioPlaying(false);
            };*/
          } else {
            await triggerVoiceSynthesis(textResponse, true);
            setIsAudioPlaying(true);
            const currentlyPlayingAudio = userHistory.length;
            setCurrentlyPlayingIndex(currentlyPlayingAudio);
            setIsAutoPlayInProgress(false); // Reset auto-play in progress
            setIsAudioPlaying(false);
          }
        }
      } catch (error) {
        console.error(error);
      }
    };

    playResponseVoice();

    return () => {
      window.removeEventListener("click", handleWindowClick);
    };
  }, [textResponse, audioData, userHistory]);

  const handleWindowClick = () => {
    setShowDropdown(false);
  };

  const removeClasses = () => {
    const elements = document.querySelectorAll(
      " .start-main-inner-container, .start-central-navbar-backside-color, .start-sharebtn-wrapper, .start-bg-color, .start-chat-suggesst-title, .start-suggession-starter, .start-central-greeting-wrapper, .start-central-column-bbtn-wrapper, .start-input-container, .chat-help-btns-wrapper, .start-form, .start-logo-profile-img, .start-menu-logo-wrapper, .start-logo-profile, .start-menu-logo-kps-wrapper, .start-container,.start-q-and-a-container, .start-main-background-container,.start-nav-img, .start-nav-li, .start-mobile-navbar-items, .start-mobile-text, .start-main-three-colum-layout, .start-mobile-text-wrapper, .start-root, .start-output-area, .start-right-column,.start-central-column, .start-cental-video-wrapper,  .start-right-column, .start-left-column"
    );
    elements.forEach((element) => {
      element.classList.remove(
        "start-central-navbar-backside-color",
        "start-main-inner-container",
        "start-container",
        "start-central-column",
        "start-output-area",
        "start-right-column",
        "start-cental-video-wrapper",
        "start-right-column",
        "start-left-column",
        "start-mobile-text",
        "start-main-three-colum-layout",
        "start-root",
        "start-mobile-text-wrapper",
        "start-main-background-container",
        "start-nav-img",
        "start-nav-li",
        "start-mobile-navbar-items",
        "start-q-and-a-container",
        "start-input-container",
        "start-menu-logo-kps-wrapper",
        "start-logo-profile",
        "start-menu-logo-wrapper",
        "start-logo-profile-img",
        "start-form",
        "chat-help-btns-wrapper",
        "start-central-greeting-wrapper",
        "start-central-column-bbtn-wrapper",
        "start-suggession-starter",
        "start-chat-suggesst-title",
        "start-bg-color",
        "start-sharebtn-wrapper"
      );
    });
  };

  const htmlContent = ``;
  const regex = /\*\*(.*?)\*\*/g;
  const styledHtmlContent = htmlContent.replace(
    regex,
    '<span class="bold-text">$1</span>'
  );

  //conversation starter
  const changeSelectedOption = async (option = "option1") => {
    try {
      // Send the selected option to the backend
      const res = await axiosInstance.post(CONVERSATION_STARTER, {
        selectedOption: option,
      });
      console.log("Option sent to backend:", option);
      //setIsSubmitting(true);
      setResponseTexts(res.data.responses);
      console.log(res.data.responses);
    } catch (error) {
      console.error("Error sending option to backend:", error);
    }
  };

  const handlechtst1ButtonClick = async () => {
    try {
      await changeSelectedOption();
      // Send the button text to the response endpoint
      const response = await axios.post(CONV_RESPONSE, {
        buttonText: responseTexts,
      });

      // Get the response back from the backend
      const { conversation: backendResponse } = response.data;

      console.log("Backend Response:", backendResponse);
    } catch (error) {
      console.error("Error sending button text to backend:", error);
    }
  };

  //option handling

  const changeSelectedOptionToOption2 = () => {
    mixpanel.track('Symptom Checker', { 'Button Type': 'sympotomchecker' })
    setSelectedOption("option2");
    changeSelectedOption("optioin2");
    setBackgroundColor("#f0f3f4");
    setBackgroundreturnColor("#dbf4fa");
    // setBackgroundreturnColor("#C1D6F0");
    setBackgroundText("Symptoms Checker");
    setBackgroundImg(symptomChecker);
    setBackgroundMp(symptomCheckerMp4);
    setReturnBar("option2");
    setCenterHome(false);
    setImgSuggession(symptomChecker);
    setSuggessionTitle("Let's understand your symptoms together and discuss tailored guidelines.");
    setBoxSuggession("#eeeeee");
  };

  const changeSelectedOptionToOption3 = () => {
    mixpanel.track('Report Explainer', { 'Button Type': 'reportexplainer' })
    setSelectedOption("option3");
    changeSelectedOption("optioin3");
    // setBackgroundColor("#e7fafc");
    setBackgroundColor("#f0f3f4");
    setCenterHome(false);
    setBackgroundreturnColor("#b3edf2");
    setBackgroundText("Report Explainer");
    setBackgroundImg(reportAnalyzer);
    setBackgroundMp(reportAnalyzerMp4);
    setReturnBar("option3");
    setImgSuggession(reportAnalyzer);
    setSuggessionTitle("I'll analyze your health reports in detail, providing insights and practical suggestions.")
    setBoxSuggession("#eeeeee");
  };

  const changeSelectedOptionToOption4 = () => {
    mixpanel.track('Healthy Foodie', { 'Button Type': 'healthyfoodie' })
    setSelectedOption("option4");
    changeSelectedOption("optioin4");
    setBackgroundColor("#f0f3f4");
    // setBackgroundColor("#fef0f2");
    setBackgroundreturnColor("#efacae");
    setBackgroundText("Healthy Foodie");
    setBackgroundImg(calorieCounter);
    setBackgroundMp(calorieCounterMp4);
    setImgSuggession(calorieCounter);
    setSuggessionTitle("Count calories effortlessly with my help, maintaining a healthy diet to reach your fitness goals.");
    setReturnBar("option4");
    setBoxSuggession("#eeeeee");
    setCenterHome(false);
  };

  const changeSelectedOptionToOption6 = () => {
    mixpanel.track('Mind Coach', { 'Button Type': 'mindcoach' })
    setSelectedOption("option6");
    setBackgroundColor("#f0f3f4");
    // setBackgroundColor("#f8fff5");
    setBackgroundreturnColor("#cce7b1");
    setBackgroundText("Mind Coach");
    setBackgroundImg(wellnessCouch);
    setBackgroundMp(wellnessCouchMp4);
    setImgSuggession(wellnessCouch);
    setSuggessionTitle("Think of me as a caring friend who offers practical advice for overall well-being.");
    setReturnBar("option6");
    setBoxSuggession("#eeeeee");
    setCenterHome(false);
  };

  const changeSelectedOptionToOption7 = () => {
    mixpanel.track('Sleep Helper', { 'Button Type': 'sleephelper' })
    setSelectedOption("option7");
    changeSelectedOption("optioin7");
    setBackgroundColor("#f0f3f4");
    // setBackgroundColor("#fffbf0");
    setBackgroundreturnColor("#f9e29c");
    setCenterHome(false);
    setBackgroundText("Sleep Helper");
    setBackgroundImg(sleepHelper);
    setBackgroundMp(sleepHelperMp4);
    setImgSuggession(sleepHelper);
    setSuggessionTitle("Improve sleep quality with personalized tips from me, addressing sleep issues effectively.");
    setReturnBar("option7");
    setBoxSuggession("#eeeeee");
  };

  const changeSelectedOptionToOption8 = () => {
    mixpanel.track('Workout Planner', { 'Button Type': 'workoutplanner' })
    setSelectedOption("option8");
    changeSelectedOption("optioin8");
    setBackgroundColor("#f0f3f4");
    // setBackgroundColor("#f7efff");
    setBackgroundreturnColor("#c5b2fa");
    setCenterHome(false);
    setBackgroundText("Workout Planner");
    setBackgroundImg(workoutPlanner);
    setBackgroundMp(workoutPlannerMp4);
    setImgSuggession(workoutPlanner);
    setSuggessionTitle("Plan effective exercise routines with my expertise in fitness and health.");
    setReturnBar("option8");
    setBoxSuggession("#eeeeee");
  };

  const changeSelectedOptionToOption9 = () => {
    mixpanel.track('Women’s Health', {'Button Type': 'Women’shealth'})
    setSelectedOption("option9");
    changeSelectedOption("optioin9");
    setBackgroundColor("#f0f3f4");
    // setBackgroundColor("#fff0fc");
    setBackgroundreturnColor("#ecacd6");
    setCenterHome(false);
    setBackgroundText("Women’s Health");
    setBackgroundImg(womenHealth);
    setBackgroundMp(investorRelationsMp4);
    setImgSuggession(womenHealth);
    setSuggessionTitle("Knightingale’s AI health guide tailored specifically for women's wellness.");
    setReturnBar("option9");
    setBoxSuggession("#eeeeee");
  };

  const changeSelectedOptionToOption11 = () => {
    mixpanel.track('Stress Regulator', { 'Button Type': 'stressregulator' })
    setSelectedOption("option11");
    changeSelectedOption("optioin11");
    setBackgroundColor("#f0f3f4");
    // setBackgroundColor("#fff2eb");
    setBackgroundreturnColor("#f9c399");
    setCenterHome(false);
    setBackgroundText("Stress Regulator");
    setBackgroundImg(careReminder);
    setBackgroundMp(careReminderMp4);
    setImgSuggession(careReminder);
    setSuggessionTitle("Empowering your well-being, one gentle reminder at a time.");
    setReturnBar("option11");
    setBoxSuggession("#eeeeee");
  };

  const inputFileRef = useRef(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedImage(file.name);
      // Create a FormData object to send the image

      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };

      reader.readAsDataURL(file);
      const formData = new FormData();
      formData.append("image", file);

      // Send the image to the server using an HTTP POST request
      axios
        .post(VISION, formData)
        .then((response) => {
          // Handle the server's response here, which may contain the local file path where the image is saved.
          console.log("Image uploaded:", response.data.filePath);
        })
        .catch((error) => {
          console.error("Error uploading image:", error);
        });
    }
  };

  const handleImageUpload = () => {
    if (inputFileRef.current) {
      inputFileRef.current.click();
    }
  };

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  // console.log(imgSuggession)
  return (
    <div
    // below using to 24/7 chat
    // className="main-background-container background-video start-main-background-container"

      className="main-background-container background-video "
    // style={{ backgroundImage: `url(${backgroundVideo})` }}
    >
          {/* // below using to 24/7 chat */}
      {/* <div className="main-inner-container container start-main-inner-container"> */}
      <div className="main-inner-container container ">

        <form
          /* // below using to 24/7 chat */

          // className="kps-chat-form form start-form"
          className="kps-chat-form form "
          onSubmit={handleSubmit}
        // style={marginStyle}
        //  style={/iPhone|iPod|iPad/i.test(navigator.userAgent)? { margin: "0 0 80px 0" } : { margin: "0" }}
        >
          <select
            className="select-dropdown"
            value={selectedOption}
            onChange={handleOptionChange}
            disabled={!showOptions}
            required
          >
            <option value="option5">Select a goal</option>
            <option value="option1">Knightingale</option>
            <option value="option2">Symptom Guide</option>
            <option value="option3">Report Analyzer</option>
            <option value="option4">Calorie Counter</option>
            <option value="option6">Wellness Coach</option>
            <option value="option7">Sleep Helper</option>
            <option value="option8">Workout Planner</option>
            <option value="option9">Women’s Health</option>
            <option value="option10">Guru Lexi</option>
            <option value="option11">Care Reminder</option>
          </select>

          {isSelectSuggest ? (
            <>
              <div className="chat-suggesst-title  "></div>
            </>
          ) : null}

          <Chat
            userHistory={userHistory}
            currentlyPlayingIndex={currentlyPlayingIndex}
            isVoiceSynthesisInProgress={isVoiceSynthesisInProgress}
            handleStopButtonClick={handleStopButtonClick}
            playAudio={playAudio}
            showLoadingIndicator={showLoadingIndicator}
            handlechtst1ButtonClick={handlechtst1ButtonClick}
            setInput={setInput}
            responseTexts={responseTexts}
            imgSuggession={imgSuggession}
            suggessionTitle={suggessionTitle}
            selectedOption={selectedOption}
            boxSuggession={boxSuggession}
          />

          <div className="mobile-voice-container">
            <button
              type="button"
              className=" ripple-btn"
              onClick={handleMicButtonClick}
              disabled={isSubmitting}
            >
              <img
                className="chat-logo-image "
                src={voiceLogo}
                alt="Microphone"
              />
            </button>
            <span className="mobile-divider"></span>
          </div>
          {/* <div className="sharebtn-wrapper start-sharebtn-wrapper">
            <div className="sharebtn-inner">
              <div className="sharebtn">
                <button className="sharebtn-img" onClick={() => setShowShareWindow(true)}>
                <div class="tooltip">
                <img className="share" src={share} alt="share" />
                    <span class="tooltiptext">Share</span>
                  </div>
                  
                  
                  </button>
                {showShareWindow && (
                  <ShareWindow
                    generatedLink={uniqueLink}
                    onClose={() => setShowShareWindow(false)}
                  />
                )}
              </div>
            </div>
          </div> */}

          {/* below using to 24/7 */}

          {/* <div className="input-container start-input-container"> */}
          <div className="input-container ">
            <ChatInput
              setReturnBar={setReturnBar}
              textareaRef={textareaRef}
              input={input}
              setInput={setInput}
              getLetterCount={getLetterCount}
              handleInputChange={handleInputChange}
              handleKeyDown={handleKeyDown}
              handleKeyUp={handleKeyUp}
              isSubmitting={isSubmitting}
              disableMicButton={disableMicButton}
              getPlaceholderText={getPlaceholderText}
              suggestions={suggestions}
              showDropdown={showDropdown}
              handleSuggestionClick={handleSuggestionClick}
              disableSendButton={disableSendButton}
              handleMicButtonClick={handleMicButtonClick}
              isVoiceRecognitionActive={isVoiceRecognitionActive}
              dropdownRef={dropdownRef}
              getStyles={getStyles}
              isRecording={isRecording}
              handleImageChange={handleImageChange}
              inputFileRef={inputFileRef}
              handleImageUpload={handleImageUpload}
              selectedImage={selectedImage}
              imagePreview={imagePreview}
              boxSuggession={boxSuggession}
              selectedOption={selectedOption}
              setCenterHome={setCenterHome}
            />
          </div>

          {/* <div className="chat-help-btns-wrapper chat-help-btns-wrapper-none">
            <div className="chat-help-btns-container">


              <div className="stage-2-3-wrapper">
                <div className="chat-help-btns-stage stage-2">
                  <div className="button-wrapper">
                    <div
                      style={{
                        backgroundColor:
                          selectedOption === "option2" ? "#E9F3FF" : "",
                        borderColor:
                          selectedOption === "option2" ? "#00000010" : "",
                      }}
                      type="button"
                      onClick={changeSelectedOptionToOption2}
                      className={`calorie counter button ${selectedOption !== "option2" ? "button-hover" : ""
                        }`}
                    >

                      <img
                        className="chat-help-btn-img-st2"
                        src={symptomChecker}
                        alt="Symptom Checker"
                      />
                      <span>
                        Symptoms
                        <br /> Checker
                      </span>
                    </div>
                  </div>
                  <div className="button-wrapper">
                    <div
                      style={{
                        backgroundColor:
                          selectedOption === "option3" ? "#C2FAFE" : "",
                        borderColor:
                          selectedOption === "option3" ? "#00000010" : "",
                      }}
                      type="button"
                      onClick={changeSelectedOptionToOption3}
                      className={`relationship guru button ${selectedOption !== "option3" ? "button-hover" : ""
                        }`}
                    >
                      <img
                        className="chat-help-btn-img-st2"
                        src={reportAnalyzer}
                        alt=" Report Analyzer"
                      />
                      <span>
                        Report
                        <br /> Explainer
                      </span>
                    </div>
                  </div>
                  <div className="button-wrapper">
                    <div
                      style={{
                        backgroundColor:
                          selectedOption === "option4" ? "#FFBDBD" : "",
                        borderColor:
                          selectedOption === "option4" ? "#00000010" : "",
                      }}
                      type="button"
                      onClick={changeSelectedOptionToOption4}
                      className={`pregnancy manager button ${selectedOption !== "option4" ? "button-hover" : ""
                        }`}
                    >
                      <img
                        className="chat-help-btn-img-st2"
                        src={calorieCounter}
                        alt="Calorie Counter "
                      />
                      <span>
                        Healthy
                        <br /> Foodie
                      </span>
                    </div>
                  </div>
                  <div className="button-wrapper">
                    <div
                      style={{
                        backgroundColor:
                          selectedOption === "option6" ? "#E6FDD8" : "",
                        borderColor:
                          selectedOption === "option6" ? "#00000010" : "",
                      }}
                      type="button"
                      onClick={changeSelectedOptionToOption6}
                      className={`parenting coach button ${selectedOption !== "option6" ? "button-hover" : ""
                        }`}
                    >
                      <img
                        className="chat-help-btn-img-st2"
                        src={wellnessCouch}
                        alt=" Wellness Coach"
                      />
                      <span>
                        Mind
                        <br />
                        Coach
                      </span>
                    </div>
                  </div>
                </div>

                <div className="chat-help-btns-stage stage-3">
                  <div className="button-wrapper">
                    <div
                      style={{
                        backgroundColor:
                          selectedOption === "option7" ? "#FFF5CD" : "",
                        borderColor:
                          selectedOption === "option7" ? "#00000010" : "",
                      }}
                      type="button"
                      onClick={changeSelectedOptionToOption7}
                      className={`calorie counter button ${selectedOption !== "option7" ? "button-hover" : ""
                        }`}
                    >
                      <img
                        className="chat-help-btn-img-st2"
                        src={sleepHelper}
                        alt="Sleep Helper "
                      />
                      <span>
                        Sleep
                        <br /> Helper
                      </span>
                    </div>
                  </div>

                  <div className="button-wrapper">
                    <div
                      style={{
                        backgroundColor:
                          selectedOption === "option8" ? "#DFCFFF" : "",
                        borderColor:
                          selectedOption === "option8" ? "#00000010" : "",
                      }}
                      type="button"
                      onClick={changeSelectedOptionToOption8}
                      className={`relationship guru button ${selectedOption !== "option8" ? "button-hover" : ""
                        }`}
                    >
                      <img
                        className="chat-help-btn-img-st2"
                        src={workoutPlanner}
                        alt="Workout Planner"
                      />
                      <span>
                        Workout
                        <br /> Planner
                      </span>
                    </div>
                  </div>

                  <div className="button-wrapper">
                    <div
                      style={{
                        backgroundColor:
                          selectedOption === "option9" ? "#FFCAEB" : "",
                        borderColor:
                          selectedOption === "option9" ? "#00000010" : "",
                      }}
                      type="button"
                      onClick={changeSelectedOptionToOption9}
                      className={`pregnancy manager button ${selectedOption !== "option9" ? "button-hover" : ""
                        }`}
                    >
                      <img
                        className="chat-help-btn-img-st2"
                        src={womenHealth}
                        alt="Investor Relations"
                      />
                      <span>

                       Women’s 
                      <br />
                      Health
                      </span>
                    </div>
                  </div>

                  <div className="button-wrapper">
                    <div
                      style={{
                        backgroundColor:
                          selectedOption === "option11" ? "#FFE0CA" : "",
                        borderColor:
                          selectedOption === "option11" ? "#00000010" : "",
                      }}
                      type="button"
                      onClick={changeSelectedOptionToOption11}
                      className={`parenting coach button ${selectedOption !== "option11" ? "button-hover" : ""
                        }`}
                    >
                      <img
                        className="chat-help-btn-img-st2"
                        src={careReminder}
                        alt="Care Reminder"
                      />
                      <span>
                        Stress
                        <br /> Regulator
                      </span>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div> */}
        </form>
      </div>
    </div>
  );
}

export default Form;
