import React, { useState, useEffect } from 'react';
import { useSpring, animated } from 'react-spring';

function AudioVisualization() {
  const [audioContext, setAudioContext] = useState(null);
  const [analyserNode, setAnalyserNode] = useState(null);
  const [microphoneStream, setMicrophoneStream] = useState(null);
  const [animationProps, setAnimationProps] = useSpring(() => ({
    scale: 1,
  }));
  const [isVisualizing, setIsVisualizing] = useState(false);
  const [isMicOn, setIsMicOn] = useState(false);

  useEffect(() => {
    const initAudio = async () => {
      try {
        const audioContext = new (window.AudioContext || window.webkitAudioContext)();
        const analyserNode = audioContext.createAnalyser();
        analyserNode.fftSize = 256;

        setAudioContext(audioContext);
        setAnalyserNode(analyserNode);

        if (isMicOn) {
          const audioStream = await navigator.mediaDevices.getUserMedia({ audio: true });
          const microphone = audioContext.createMediaStreamSource(audioStream);
          microphone.connect(analyserNode);
          analyserNode.connect(audioContext.destination);
          setMicrophoneStream(audioStream);
        }
      } catch (error) {
        console.error('Error initializing audio:', error);
      }
    };

    initAudio();
  }, [isMicOn]);

  useEffect(() => {
    if (!analyserNode || !isVisualizing) return;

    const dataArray = new Uint8Array(analyserNode.frequencyBinCount);

    const updateAnimation = () => {
      analyserNode.getByteFrequencyData(dataArray);
      const averageVolume = dataArray.reduce((acc, val) => acc + val, 0) / dataArray.length;

      setAnimationProps({ scale: 1 + averageVolume / 100 });
      requestAnimationFrame(updateAnimation);
    };

    updateAnimation();
  }, [analyserNode, setAnimationProps, isVisualizing]);

  const toggleVisualization = () => {
    setIsVisualizing((prevIsVisualizing) => !prevIsVisualizing);
  };

  const toggleMic = () => {
    setIsMicOn((prevIsMicOn) => {
      if (!prevIsMicOn && !microphoneStream) {
        navigator.mediaDevices
          .getUserMedia({ audio: true })
          .then((audioStream) => {
            const microphone = audioContext.createMediaStreamSource(audioStream);
            microphone.connect(analyserNode);
            analyserNode.connect(audioContext.destination);
            setMicrophoneStream(audioStream);
          })
          .catch((error) => {
            console.error('Error accessing the microphone:', error);
          });
      } else if (prevIsMicOn && microphoneStream) {
        const tracks = microphoneStream.getTracks();
        tracks.forEach((track) => {
          track.stop(); // Stop the microphone track
        });
        setMicrophoneStream(null);
      }
      return !prevIsMicOn;
    });
  };

  return (
    <div>
      <animated.div
         style={{
          width: '100px',
          height: '100px',
          backgroundColor: 'blue',
          transform: animationProps.scale.interpolate((s) => `scale(${s})`),
          // Additional styles
          height: '90%',
          width: '90%',
          maxWidth: '42px',
          margin: '0px',
          color: '#333',
          background: '#fff',
          boxShadow: '0 0 0px 0px rgba(255, 255, 255, 0.5)',
          cursor: 'pointer',
          borderRadius: '50%',
          WebkitBorderRadius: '50%',
          MozBorderRadius: '50%',
          MsBorderRadius: '50%',
          OBorderRadius: '50%',
          transition: '250ms color',
          WebkitTransition: '250ms color',
          MozTransition: '250ms color',
          MsTransition: '250ms color',
          OTransition: '250ms color',
          animationName: 'wave',
          animationDuration: '1s',
          animationTimingFunction: 'linear',
          animationIterationCount: 'infinite',
        }}
      />
      <button onClick={toggleVisualization}>
        {isVisualizing ? 'Stop Visualization' : 'Start Visualization'}
      </button>
      <button onClick={toggleMic}>
        {isMicOn ? 'Turn Mic Off' : 'Turn Mic On'}
      </button>
    </div>
  );
}

export default AudioVisualization;
